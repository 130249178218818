import defaultUserImg from "./assets/images/user-default.png";

export const usersTableData = [
  {
    id: "0",
    image: defaultUserImg,
    name: "WWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWW",
    titles: [
      "WWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWW",
      "ada",
      "asadad",
      "asasa",
      "arfwqfwq",
    ],
    company:
      "WWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWW",
    onboardingStatus: "Completed",
    location:
      "WWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWW",
    joiningDate: "08/12/2023",
    accountStatus: "Active",
    aadhaarVerified: "Verified",
  },
  {
    id: "1",
    image: defaultUserImg,
    name: "Nathaniel Bustos",
    titles: [],
    company: "Rextone digital",
    onboardingStatus: "Pending",
    location: "Mumbai, India",
    joiningDate: "08/12/2023",
    accountStatus: "Active",
    aadhaarVerified: "Verified",
  },
  {
    id: "2",
    image: defaultUserImg,
    name: "Nathaniel Bustos",
    titles: ["Manager"],
    company: "Rextone digital",
    onboardingStatus: "Completed",
    location: "Mumbai, India",
    joiningDate: "08/12/2023",
    accountStatus: "Inactive",
    aadhaarVerified: "Verified",
  },
  {
    id: "3",
    image: defaultUserImg,
    name: "Nathaniel Bustos",
    titles: ["Manager"],
    company: "Rextone digital",
    onboardingStatus: "Pending",
    location: "Mumbai, India",
    joiningDate: "08/12/2023",
    accountStatus: "Active",
    aadhaarVerified: "Verified",
  },
  {
    id: "4",
    image: defaultUserImg,
    name: "Nathaniel Bustos",
    titles: [
      "Manager",
      "abc",
      "asdas",
      "grgreg",
      "dqwrqwr",
      "ytujtyj",
      "gewgewg",
    ],
    company: "Rextone digital",
    onboardingStatus: "Pending",
    location: "Mumbai, India",
    joiningDate: "08/12/2023",
    accountStatus: "Inactive",
    aadhaarVerified: "Verified",
  },
  {
    id: "5",
    image: defaultUserImg,
    name: "Nathaniel Bustos",
    titles: ["Manager"],
    company: "Rextone digital",
    onboardingStatus: "Completed",
    location: "Mumbai, India",
    joiningDate: "08/12/2023",
    accountStatus: "Active",
    aadhaarVerified: "Verified",
  },
  {
    id: "6",
    image: defaultUserImg,
    name: "Nathaniel Bustos",
    titles: ["Manager"],
    company: "Rextone digital",
    onboardingStatus: "Completed",
    location: "Mumbai, India",
    joiningDate: "08/12/2023",
    accountStatus: "Active",
    aadhaarVerified: "Verified",
  },
  {
    id: "7",
    image: defaultUserImg,
    name: "Nathaniel Bustos",
    titles: ["Manager"],
    company: "Rextone digital",
    onboardingStatus: "Completed",
    location: "Mumbai, India",
    joiningDate: "08/12/2023",
    accountStatus: "Active",
    aadhaarVerified: "Verified",
  },
  {
    id: "8",
    image: defaultUserImg,
    name: "Nathaniel Bustos",
    titles: ["Manager"],
    company: "Rextone digital",
    onboardingStatus: "Completed",
    location: "Mumbai, India",
    joiningDate: "08/12/2023",
    accountStatus: "Active",
    aadhaarVerified: "Verified",
  },
  {
    id: "9",
    image: defaultUserImg,
    name: "Nathaniel Bustos",
    titles: ["Manager"],
    company: "Rextone digital",
    onboardingStatus: "Completed",
    location: "Mumbai, India",
    joiningDate: "08/12/2023",
    accountStatus: "Active",
    aadhaarVerified: "Verified",
  },
];

export const onBoardingStatus = [
  { value: "", label: "All" },
  { value: "completed", label: "Completed" },
  { value: "pending", label: "Pending" },
];

export const sortOptions = [
  { value: "name", label: "Name" },
  { value: "organization", label: "Organisation" },
  { value: "title", label: "Title" },
  { value: "location", label: "Location" },
];