import React from 'react'
import './leads.scss'

const Leads = () => {
  return (
    <div className='leads_page'>
      Leads - WIP
    </div>
  )
}

export default Leads
