import React from 'react'

const InfoHeader = ({title}) => {
    return (
        <>
            <div className='divident-heading-wrapper'>
                <h6 className='divident-heading'>{title}</h6>
            </div>

        </>
    )
}

export default InfoHeader
