export const onBoardingStatus = [
  { value: "all", label: "All" },
  { value: "completed", label: "Completed" },
  { value: "pending", label: "Pending" },
];

export const filterOptions = [
  { value: "name", label: "Name" },
  { value: "screenName", label: "Screen name" },
];

export const feedbackTableheaderText = {
  headerOne: "Name",
  headerTwo: "Screen name",
  headerThree: "Feedback comment",
  headerFour: "Feedback score",
  headerFive: "Date",
  headerSix: "Time",
  headerSeven: "Onboarding status",
};
