import React from 'react'
import { copyIcon } from '../../../../../assets/Images'
import { copyText } from '../../../../../helpers/globalFunctions'

const DarkCoder = ({ data, value, styleClass }) => {
    //onClick={()=>navigator.clipboard.writeText(value)}
    console.log(value, 'jjjjjjjjjjjjjjjjjj')
    return (
        <>
            <span className={`${styleClass}`}>
                {`${data} - ${value == undefined ? 'N/A' : value}`}
            </span>
            <img src={copyIcon} alt='copyIcon' onClick={() => copyText(value)} />

        </>
    )
}

export default DarkCoder
