import React from 'react'
import './main.scss'

const Main = () => {
    return (
        <div className='main_page'>
            Dashboard WIP
        </div>
    )
}

export default Main
