import React from 'react'
import './needs.scss'

const Needs = () => {
  return (
    <div className='needs'>
      Needs - WIP
    </div>
  )
}

export default Needs
