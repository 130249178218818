import { useCallback, useEffect, useState } from "react";
import UsersTableCard from "./UsersTableCard/UsersTableCard";
import {
  onBoardingStatus,
  sortOptions,
  usersTableData,
} from "./UsersTableCard/data";
import { axiosInstanceHeader } from "../../../helpers/axiosInstance";
import { API } from "../../../helpers/apiEndpoints";
import _ from "lodash";

const Users = () => {
  const [usersData, setUsersData] = useState([]);
  const [totalUsers, setTotalUsers] = useState("");
  const [activePage, setActivePage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [searchKey, setSearchKey] = useState(sortOptions[0]?.value);
  const [onboardingStatus, setOnboardingStatus] = useState("");
  const [userLoader, setUsersLoader] = useState(false);
  const itemsPerPage = 10;

  useEffect(() => {
    deBouncedfetchUsers(searchKey, searchText, onboardingStatus);
  }, [activePage, searchText, onboardingStatus]);

  const deBouncedfetchUsers = useCallback(_.debounce((searchKey, searchText, onboardingStatus) => {
    if (searchText?.toLowerCase() !== debouncedSearch?.toLowerCase()) setActivePage(0);
    fetchUsers(searchKey, searchText, onboardingStatus);
    setDebouncedSearch(searchText);
  }, 300),[activePage]);

  const fetchUsers = (searchKey, searchVal, onboardingStatus) => {
    setUsersLoader(true);
    axiosInstanceHeader.post(`${API.ALL_USERS}?start=${(activePage * itemsPerPage) + 1}&offset=${itemsPerPage}
      &searchKey=${searchKey}&searchValue=${searchVal?.toLowerCase()}&onboardingStatus=${onboardingStatus}`)
      .then(res => {
        setTotalUsers(res?.data?.totalUserCount)
        setUsersData(res?.data?.result)
        setUsersLoader(false);
      })
      .catch(err => {console.log(err, 'eeeeeeeeeee'); setUsersLoader(false)})
  }

  return (
    <>
      <UsersTableCard
        tableHeaderTextOne="Name"
        tableHeaderTextTwo="Organisation"
        tableHeaderTextThree="Onboarding status"
        tableHeaderTextFour="Location"
        tableHeaderTextFive="Joining date"
        tableHeaderTextSix="Account status"
        tableHeaderTextSeven="Aadhaar verified"
        usersTableData={usersTableData}
        onBoardingStatus={onBoardingStatus}
        sortOptions={sortOptions}
        data={usersData}
        count={totalUsers}
        itemsPerPage={itemsPerPage}
        activePage={activePage} 
        setActivePage={setActivePage}
        searchText={searchText}
        setSearchText={setSearchText}
        setSearchKey={setSearchKey}
        setOnboardingStatus={setOnboardingStatus}
        searchKey={searchKey}
        userLoader={userLoader}
      />
    </>
  );
};

export default Users;
