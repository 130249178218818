import React from 'react'
import InfoHeader from './Components/InfoHeader'
import Info from './Components/Info'
import { dateTime } from '../../../../../../helpers/globalFunctions';
import Spinner from 'react-bootstrap/Spinner'

const DeviceInfoChip = ({ userDetails, userDetailsLoader }) => {
    const { loginDetails } = userDetails;

    return (
        <>
            <div className='Info-wrapper'>

                <InfoHeader title='Login details' />
                <div className='loginDetails-wrapper row'>
                  {userDetailsLoader ? <div className="user-details-spinner-container">
                        <Spinner animation="border" variant="danger" className="user-details-spinner-loader"/>
                    </div> : <>
                    <div className='detail-wrapper mb-2 col-lg-6 col-md-6 col-xl-4 col-12'>
                        <Info title={"Last login time"} value={dateTime(loginDetails?.lastLoginTime)} />
                        <Info title={"Current device details"} value={loginDetails?.currentDeviceDetails} />
                        <Info title={"Network"} value={loginDetails?.network} />
                    </div>
                    <div className='detail-wrapper mb-2 col-lg-6 col-md-6 col-xl-4 col-12'>
                        <Info title={"Last logout time"} value={dateTime(loginDetails?.lastLogoutTime)} />
                        <Info title={"Last logout location"} value={loginDetails?.lastLogoutLocation?.city + ' ' + loginDetails?.lastLogoutLocation?.country} />
                    </div>
                    <div className='detail-wrapper mb-2 col-lg-6 col-md-6 col-xl-4 col-12'>
                        <Info title={"Last accessed location"} value={loginDetails?.lastAccessedLocation?.city + " " + loginDetails?.lastAccessedLocation?.country} />
                        <Info title={"Sign in via"} value={loginDetails?.signInVia} />
                        <Info title={"OTP"} value={loginDetails?.otp} />
                    </div>
                  </>}
                </div>

            </div>



        </>
    )
}

export default DeviceInfoChip
