export const accordionData = [
  {
    id: 1,
    question: "I am personally really good at",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vel orci eu est rhoncus aliquet. Mauris bibendum feugiat nisl, ut suscipit lorem ullamcorper pretiu.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vel orci eu est rhoncus aliquet. Mauris bibendum feugiat nisl, ut suscipit lorem ullamcorper pretiuLorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vel orci eu est rhoncus aliquet. Mauris bibendum feugiat nisl, ut suscipit lorem ullamcorper pretiuLorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vel orci eu est rhoncus aliquet. Mauris bibendum feugiat nisl, ut suscipit lorem ullamcorper pretiuLorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vel orci eu est rhoncus aliquet. Mauris bibendum feugiat nisl, ut suscipit lorem ullamcorper pretiuLorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vel orci eu est rhoncus aliquet. Mauris bibendum feugiat Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vel orci eu est rhoncus aliquet. Mauris bibendum feugiat nisl, ut suscipit lorem ullamcorper pretiu.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vel orci eu est rhoncus aliquet. Mauris bibendum feugiat nisl, ut suscipit lorem ullamcorper pretiuLorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vel orci eu est rhoncus aliquet. Mauris bibendum feugiat nisl, ut suscipit lorem ullamcorper pretiuLorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vel orci eu est rhoncus aliquet. Mauris bibendum feugiat nisl, ut suscipit lorem ullamcorper pretiuLorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vel orci eu est rhoncus aliquet. Mauris bibendum feugiat nisl, ut suscipit lorem ullamcorper pretiuLorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vel orci eu est rhoncus aliquet. Mauris bibendum feugiat",
  },
  {
    id: 2,
    question: "I failed and learnt at this",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vel orci eu est rhoncus aliquet. Mauris bibendum feugiat nisl, ut suscipit lorem ullamcorper pretiu.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vel orci eu est rhoncus aliquet. Mauris bibendum feugiat nisl, ut suscipit lorem ullamcorper pretiuLorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vel orci eu est rhoncus aliquet. Mauris bibendum feugiat nisl, ut suscipit lorem ullamcorper pretiuLorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vel orci eu est rhoncus aliquet. Mauris bibendum feugiat nisl, ut suscipit lorem ullamcorper pretiuLorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vel orci eu est rhoncus aliquet. Mauris bibendum feugiat nisl, ut suscipit lorem ullamcorper pretiuLorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vel orci eu est rhoncus aliquet. Mauris bibendum feugiat",
  },
  {
    id: 3,
    question: "Why hire or do business with me ?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vel orci eu est rhoncus aliquet. Mauris bibendum feugiat nisl, ut suscipit lorem ullamcorper pretiu.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vel orci eu est rhoncus aliquet. Mauris bibendum feugiat nisl, ut suscipit lorem ullamcorper pretiuLorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vel orci eu est rhoncus aliquet. Mauris bibendum feugiat nisl, ut suscipit lorem ullamcorper pretiuLorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vel orci eu est rhoncus aliquet. Mauris bibendum feugiat nisl, ut suscipit lorem ullamcorper pretiuLorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vel orci eu est rhoncus aliquet. Mauris bibendum feugiat nisl, ut suscipit lorem ullamcorper pretiuLorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vel orci eu est rhoncus aliquet. Mauris bibendum feugiat",
  },
  {
    id: 4,
    question: "Explain or demonstate your hunger & ambitions",
    answer: "",
    // "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vel orci eu est rhoncus aliquet. Mauris bibendum feugiat nisl, ut suscipit lorem ullamcorper pretiu.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vel orci eu est rhoncus aliquet. Mauris bibendum feugiat nisl, ut suscipit lorem ullamcorper pretiuLorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vel orci eu est rhoncus aliquet. Mauris bibendum feugiat nisl, ut suscipit lorem ullamcorper pretiuLorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vel orci eu est rhoncus aliquet. Mauris bibendum feugiat nisl, ut suscipit lorem ullamcorper pretiuLorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vel orci eu est rhoncus aliquet. Mauris bibendum feugiat nisl, ut suscipit lorem ullamcorper pretiuLorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vel orci eu est rhoncus aliquet. Mauris bibendum feugiat",
  },
];

export const skillsChipsCardsData = [
  {
    id: 1,
    headerText: "I am incredible at these skills /professionally great at",
    
  },
  {
    id: 2,
    headerText: "My favourite subjects are",
  },
  {
    id: 3,
    headerText: "My hobbies are",
  },
];


export const questions = [
  {
    id: 1,
    headerText: "I am incredible at these skills /professionally great at",
  },
  {
    id: 2,
    headerText: "My favourite subjects are",
  },
  {
    id: 3,
    headerText: "My hobbies are",
  }
]