import moment from "moment"

// export const dateFormat = (date) => {
//     return moment(date).format('DD/MM/YYYY')
// }

export const dateFormat = (timestamp) => {
    const date = new Date(parseInt(timestamp, 10));
    return moment(date).format('DD/MM/YYYY');
}

export const timeFormat = (timestamp) => {
    const formattedTime = moment(timestamp).format("hh:mma");  
    return formattedTime;
}

export const dateTime = (date) => {
    return moment(date).format('DD/MM/YYYY | HH:mm:ss A');
}

export const copyText = (text) => {
    return navigator.clipboard.writeText(text)
}


export const openLinkInNewTab = (link) => {
    // Check if the link starts with "http://" or "https://"
    if (!link.startsWith("http://") && !link.startsWith("https://")) {
        // If not, prepend "https://"
        link = "https://" + link;
    }

    return window.open(link, '_blank');
};

export const logout = (navigate, setLogoutPop, setIsLoggedIn) => {
    setIsLoggedIn(false)
    localStorage.clear();
    navigate('/');
    setLogoutPop(false);
}

export const isLoggedIn = () => {
    const loggedIn = localStorage.getItem('accessToken')
    if (!loggedIn || loggedIn === 'null' || loggedIn === '') {
        return false;
    } else {
        return true;
    }
}

export const openInMap = (lat, lon) => {
    const url = `https://www.google.com/maps/search/${lat},${lon}`;
    window.open(url, "_blank");
}

export const userLocation = (city, country) => {
    const location = "-";
    if (city && country) return city + ", " + country;
    if (city && !country) return city;
    if (!city && country) return country;
    return location;
}

// CHECKING VALID GMAIL
export const isValidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
};